export default [
  {
    name: 'English',
    code: 'en'
  },
  {
    name: 'Arabic',
    code: 'ar'
  },
  {
    name: 'Chinese (Simplified)',
    code: 'zh'
  },
  {
    name: 'Chinese (Traditional)',
    code: 'zh-TW'
  },
  {
    name: 'Danish',
    code: 'da'
  },
  {
    name: 'Dutch',
    code: 'nl'
  },
  {
    name: 'Estonian',
    code: 'et'
  },
  {
    name: 'Finnish',
    code: 'fi'
  },
  {
    name: 'French',
    code: 'fr'
  },
  {
    name: 'French (Canada)',
    code: 'fr-CA'
  },
  {
    name: 'German',
    code: 'de'
  },
  {
    name: 'Greek',
    code: 'el'
  },
  {
    name: 'Gujarati',
    code: 'gu'
  },
  {
    name: 'Hebrew',
    code: 'he'
  },
  {
    name: 'Hindi',
    code: 'hi'
  },
  {
    name: 'Hungarian',
    code: 'hu'
  },
  {
    name: 'Icelandic',
    code: 'is'
  },
  {
    name: 'Indonesian',
    code: 'id'
  },
  {
    name: 'Italian',
    code: 'it'
  },
  {
    name: 'Japanese',
    code: 'ja'
  },
  {
    name: 'Korean',
    code: 'ko'
  },
  {
    name: 'Latvian',
    code: 'lv'
  },
  {
    name: 'Lithuanian',
    code: 'lt'
  },
  {
    name: 'Malay',
    code: 'ms'
  },
  {
    name: 'Norwegian',
    code: 'no'
  },
  {
    name: 'Polish',
    code: 'pl'
  },
  {
    name: 'Portuguese',
    code: 'pt'
  },
  {
    name: 'Russian',
    code: 'ru'
  },
  {
    name: 'Spanish',
    code: 'es'
  },
  {
    name: 'Spanish (Mexico)',
    code: 'es-MX'
  },
  {
    name: 'Swedish',
    code: 'sv'
  },
  {
    name: 'Thai',
    code: 'th'
  },
  {
    name: 'Turkish',
    code: 'tr'
  },
  {
    name: 'Vietnamese',
    code: 'vi'
  },
  {
    name: 'Welsh',
    code: 'cy'
  }
]
